.typewriter-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid white;
  animation: typing 0.5s steps(40, end), blink-caret 0.75s ease-in-out infinite;
  font-size: 60px;

}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: auto;
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: white;
  }
}

.first-section {
  background-image: url(https://vodium.com/hubfs/HEADERS/HOME-HEADER-BACKGROUND.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.second-section {
  margin-top: 40px;
  padding-top: 0px;
  padding-bottom: 3rem;
}

.vodium-label {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  font-weight: bold;
}

.vodium-label-title {
  font-size: 60px;
  line-height: 60px;
}

.vodium-label-typewriter {
  font-size: 60px;
  line-height: 70px;
  height: 70px;
}

.input-disabled {
  background-color: #f0f0f0 !important;
}

* {
  box-sizing: border-box;
}

.column-1 {
  float: left;
  width: 33%;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.column-2 {
  float: left;
  width: 15%;
  padding: 10px;
}

.column-separator {
  float: left;
  width: 2px;
  color: white;
  height: 150px;
  background: white;
  margin-top: 50px;
  margin-left: 35px;
  margin-right: 50px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.text-label {
  padding-top: 15px;
}

.branding-logo {
  max-width: 600px;
  max-height: 125px;
}

.branding-text {
  padding-top: 10px;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1280px) {
  .column-1 {
    float: left;
    width: 45%;
  }

  .column-2 {
    width: 5%;
  }

  .branding-logo {
    max-width: 100%;
  }

  .column-separator {
    float: none;
    width: 0%;
    margin-top: 0px;
    height: 0%;
  }
}

@media screen and (max-width: 850px) {
  .column-1 {
    width: 100%;
  }

  .column-2 {
    width: 0%;
  }

  .branding-logo {
    max-width: 100%;
  }

  .column-separator {
    float: none;
    width: 0%;
    margin-top: 0px;
    height: 0%;
  }
}

@media screen and (max-width: 600px) {
  .column-1 {
    width: 100%;
  }

  .column-2 {
    width: 0%;
  }

  .branding-logo {
    max-width: 100%;
  }

  .column-separator {
    float: none;
    width: 0%;
    margin-top: 0px;
    height: 0%;
  }
}